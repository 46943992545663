import { Account, SamlConfig } from './../models/Account';
import { UserProfile } from './../models/AuthModels';
import { ApiResponse } from '../models/ApiResponse';
import User from '../models/User';
import BaseService from './BaseService';
import { ListFilter } from './filters/ListFilter';
import { UserFilter } from './filters/UserFilter';
import { Client, ClientUpdateDto } from '../models/Client';
import { ClientTagFilter, ClientTagResponse } from '../models/ClientTag';

export default class AdminService extends BaseService {
  public static getAccount(accountId: string): Promise<ApiResponse<Account>> {
    return this.get(`/v1/admin/accounts/${accountId}`);
  }

  public static getAccounts(filter: ListFilter): Promise<ApiResponse<Client[]>> {
    return this.get('/v1/admin/accounts', {
      params: filter,
    });
  }

  public static getAccountUsers(accountId: string, filter: UserFilter): Promise<ApiResponse<User[]>> {
    if (accountId) {
      return this.get(`/v1/admin/accounts/${accountId}/users`, {
        params: filter,
      });
    }
    return this.getUsers(filter);
  }

  public static getUsers(filter: UserFilter): Promise<ApiResponse<User[]>> {
    return this.get('/v1/admin/users', {
      params: filter,
    });
  }

  public static getUser(userId: string): Promise<ApiResponse<User>> {
    return this.get(`/v1/admin/users/${userId}`);
  }

  public static updateUser(userId: string, body: UserProfile): Promise<ApiResponse<User>> {
    return this.put(`/v1/admin/users/${userId}`, body);
  }

  public static setDefaultAccount(userId: string, accountId: string): Promise<ApiResponse<User>> {
    return this.post(`/v1/admin/users/${userId}/default-account`, { accountId });
  }

  public static updateAccountRole(userId: string, accountId: string, roleCode: string): Promise<ApiResponse<User>> {
    return this.post(`/v1/admin/users/${userId}/account-role`, { accountId, roleCode });
  }

  public static createTenant(tenant: ClientUpdateDto): Promise<ApiResponse<string>> {
    return this.post(`/v1/admin/tenants`, tenant);
  }

  public static getClientTags(accountId: string, filter: ClientTagFilter): Promise<ApiResponse<ClientTagResponse[]>> {
    return this.get(`/v1/admin/accounts/${accountId}/client-tags`, { params: filter });
  }

  public static getSamlConfig(accountId: string): Promise<ApiResponse<SamlConfig>> {
    return this.get(`/v1/admin/accounts/${accountId}/saml`);
  }

  public static upsertSamlConfig(samlConfig: SamlConfig): Promise<ApiResponse<SamlConfig>> {
    return this.post(`/v1/admin/accounts/${samlConfig.accountId}/saml`, samlConfig);
  }
}
